@import "../../../scss-utils/colors";
.slide-evaluation-questions{
  //position: absolute;
  background: white;
  //border-radius: 10px 10px 0 0;
  //width: 100%;
  //bottom: 0;
  padding: 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  //height: 15rem;
  //overflow-y: scroll;
  flex: 0.7;
  position:relative;

  @media screen and (max-width: 420px) {
    width: 100%;
  }
}
.sticky-q{
  position: sticky;
  top:10px
}


.se-question{
  margin-bottom: 15px;
}