@import "../../../scss-utils/colors";

.coding-header {
  position: fixed;
  width: 100%;
  display: flex;
  height: 3rem;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  background: $color-bg--3;
  color: $color-text;
  font-size: 1.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1);

  .flex-1 {
    flex-grow: 1;
  }

  @media screen and (max-width: 420px) {
    font-size: 1rem;
    flex-direction: column;
    height: 6rem;
    .button {
      font-size: 0.7rem;
    }
  }

}

.coding-header-text {
  margin-left: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: left;

  @media screen and (max-width: 420px) {
    display: none;
  }

}

.header-buttons {
  display: flex;
  .save {
    flex-grow: 2;
  }
}