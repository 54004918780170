.evaluation-header-row {
  display: flex;
  gap: 5px;
  //overflow-x: auto;
  .slide-question {
    width: 100px;
    //border: solid 1px red;
    overflow: hidden;
  }

  & > * {
    display: flex;
    border: black 1px solid;
    background: white;
  }

  &.second-evaluation {
    & > * {
      background-color: rgba(255, 241, 185, 0.94);
    }
  }

  &.evaluated {
    & > * {
      background-color: rgba(238, 255, 220, 0.94);
    }
  }

  &.started {
    & > * {
      background-color: rgba(220, 246, 255, 0.94);
    }
  }

  .slide-data {

    &.evaluation-disable {
      background-color: #969696;

      color: rgba(0, 0, 0, 0.5);
    }

    .slide-select {
      overflow: hidden;
      width: 35px;
      //border-left: 1px black dotted;
      cursor: help;

      &.coder-row {
        border-right: 1px black dotted;
      }
    }

    .slide-question {
      border-left: 1px solid black;
      border-right: 1px solid black;
      width: 35px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: help;
      overflow: hidden;
      line-height: 1.8rem;

      &.checked {
        background: darkseagreen;
      }
    }
  }

  .slide-time {
    padding-left: 5px;
    width: 10rem;

    &.evaluation-disable {
      background-color: #969696;

      color: rgba(0, 0, 0, 0.5);
    }
  }

  .slide-coder {
    padding-left: 5px;
    width: 5rem;

    &.evaluation-disable {
      background-color: #969696;
    }
  }

  .additional-info {
    border: none;
    background-color: unset;
  }
}