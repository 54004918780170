@import "../../../../scss-utils/colors";

.text-area-hl-main{
  //height: 100%;
  //width: 100%;
  //overflow: auto;
  //max-width: 32rem;
}

.text-area-hl-header {
  color: $color-text;
  background-color: $color-bg--1;
  display: flex;
  justify-content: center;
  padding: 8px;
  font-size: 1.5rem;
}

.text-area-hl{
  margin: 15px;
  //padding: 35px;
  border-radius: 5px;
  padding: 1.6rem;
  //max-width: 32rem;
  //min-width: 32rem;
  //font-weight: bold;
  border: solid 3px grey;
  background-color: white;
  //word-break: break-all;
}

.questions{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}