.progress-wrapper{
  border-radius: 30px;
  background-color: white;
}

.item {

  .title {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 5px;
    margin-bottom: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #666;
    border-bottom: 1px solid #f5f5f5;
    font-size: .9em;

    span {
      flex: 1;
      margin-right: 10px;
    }

    a {
      text-decoration: none;
      color: #ccc;
      font-size: .9em;
    }

  }

  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 1.2em;
    font-weight: 100;
    color: #555;
    user-select: none;
  }

  .indicator-volume {

    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 1.3em;

    .inner {
      margin-bottom: 30px;
    }

    .percentage {
      font-size: 1.6em;
      color: #bbb;
      font-weight: 100;
    }

    .icon {
      font-size: 3em;
      color: #5d9cec;
      margin-bottom: 15px;
    }

  }

}