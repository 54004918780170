.evaluation-row{
  //overflow-x: auto;
  //height: 12rem;
  //background-color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  padding: 1rem;
  &:nth-child(even) {
    background-color: #969696;
  }
  &:nth-child(odd) {
    background-color: #bdaaa9;
  }

  .entryId-admin{
    background-color: white;
    padding: 0.5rem  1rem 0.5rem 1rem;
  }

  .evaluation-header{
    display: flex;
    align-items: center;

    .item{
      border-radius: 15px;
      margin-left: 1rem;
      background: white;
      padding: 10px;
      &.blank{
        background: none;
      }
      &.entry-id{
        background-color: black;
      }
    }
  }

  &.exercise-missing{
    background-color: red;
  }

  &.exercise-uncodable{
    background-color: #ffbf6d;
  }

}