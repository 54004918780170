.account-page{
  overflow: auto;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.admin-rows-content{
  margin-top: 2.5rem;
}

.pagination-row{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.pagination{
  display:flex;
  gap:1rem;
  justify-content: center;
  border: solid 1px black;
  padding: 5px;
  border-radius: 10px;
}

.pagination-bt{
  cursor:pointer;
  padding:5px;

  :hover{
    border-bottom: solid 1px black;
  }

  &.selected{
    background: #4dffa1;
  }
}


.admin-period{
  display: flex;
  //justify-content: space-evenly;
  //align-content: flex-start;
  padding: 5rem 5rem 5rem 5rem ;
  width: 100%;
  .admin-period-side{

    flex: 1;
  }

  .admin-section{
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    gap: 1rem;
  }
}
.period-textarea{
  width: 70vw;
}