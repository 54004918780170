.count{
    border: solid 2px darkseagreen;
    font-size: 2rem;
    padding: 1rem;
    min-width: 3rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.count.checked{
    background: lightgreen;
}

.count.all{
    background: sandybrown;
}

.q-text{
    font-size: 0.5em;
}