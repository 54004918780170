.evaluation-period-page-detail{
  overflow: auto;
  height: 100vh;
  width: 100vw;
  position: relative;

}

.admin-rows-content{
  margin-top: 2.5rem;


}

.pagination-row{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.pagination{
  display:flex;
  gap:1rem;
  justify-content: center;
  border: solid 1px black;
  padding: 5px;
  border-radius: 10px;
}

.pagination-bt{
  cursor:pointer;
  padding:5px;

  :hover{
    border-bottom: solid 1px black;
  }

  &.selected{
    background: #4dffa1;
  }
}


.admin-period{
  display: flex;
  //justify-content: space-evenly;
  //align-content: flex-start;
  padding: 5rem 5rem 5rem 5rem ;
  width: 100%;
  .admin-period-side{

    flex: 1;
  }

  .admin-section{
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    gap: 1rem;
  }
}

.eval-detail-page{
  display: flex;
  flex-direction: column ;
}

.row{
  display: flex;
  align-items: center;

  &.center{
    justify-content: center;
  }

  &.gap-small{
    gap: 1rem;
  }
  &.gap-mini{
    gap: 0.3rem;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;
  }

}

.column{
  display: flex;
  flex-direction: column;

  &.center{
    justify-content: center;
  }

  &.gap-small{
    gap: 1rem;
  }
}

.content{
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  width: 100%;
  justify-content: center;
  padding-top: 3rem;
  height: 100%;
  align-items: center;
  overflow: auto;

  &.top{
    align-items: start;
    padding-top: 6rem;
  }
}

.information-text{
  padding: 10px;
  margin-left: 10px;
  border-radius: 3px;
  font-size: 1.5rem;
  background-color: white;

  &.success{
    background-color: rgba(0, 145, 75, 0.43);
  }
}