.login-wrapper {
    height: 70vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-login {
    padding-bottom: 1rem;
}

.input-login input {
    height: 2rem;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    cursor: pointer;
}

.button-wrapper button{

    cursor: pointer;
}