.App {
  text-align: center;
  display: flex;

  height: 100vh;
  width: 100vw;
  gap:3rem;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bt-navigation{
  height: 30%;
  font-size:5rem;
  cursor: pointer;
}
