.rating-page{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.id-select{
    display: flex;
    flex-direction: column;
    /*gap: 1rem;*/
    font-size: 3rem;
    align-items: center;
}

.id-select input{
    text-align: center;
    height: 4rem;
    width: 20rem;
    font-size: 3rem;
}


.id-select button{
    height: 10rem;
    width: 20rem;
    font-size: 4rem;
}