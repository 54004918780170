.rating-selection-page{
    width: 100vw;
    display: flex;
    gap: 2rem;
    flex-wrap:wrap;
    position:relative;
}
.card{
    cursor: pointer;
    background: darkseagreen;
}
.card.disabled{
    background: rgba(128, 128, 128, 0.46);
    cursor: not-allowed;
}