.comparison-page{
    margin-top: 3rem;
    overflow: auto;
    display: flex;
    flex-flow: column;
    height: calc(100vh - 3rem);
}

.comparison-grid{
    overflow-x: scroll;
}
.c-image{
    margin:1rem;
   height: 25rem;
}

.comparison-row{
    flex: 1;
    display: flex;
    min-height: 30rem;
    //height: 50rem;
    //overflow-x: auto;
    &>*{
        flex:none;
        padding: 1rem;
        height: 100%;
    }
}
.comparison-all{
    overflow-x: scroll;
    height: 100%;
    width: 100%;
}

// ------------------------------- SELECT PAGE -------------------------------
.comparison-select-page{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.card-button{
    outline: inherit;
    border: none;
    border-radius: 15px;
    font-size: 2rem;
    height: 20rem;
    width: 20rem;
    background: #969696;
    overflow: hidden;
    position: relative;

    align-items: center;
    transition: all .2s ease-in-out;

    .card-img{
        transform: scale(2);
        //pointer-events: none;
    }
    .card-name{
        padding: 2rem;
        z-index: 3;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center; /** Y-axis align **/
        justify-content: center; /** X-axis align **/
        color: #fce4e3;
        pointer-events: auto;
    }
    &:hover{
        transform: scale(1.05);
    }

    &.disabled{
        border: solid 20px rgba(183, 183, 183, 0.51);
        .card-name{
            cursor: default;
        }
        &:hover{
            transform: scale(1.0);
        }
    }

    &.warning{
        border-bottom: solid 30px rgba(255, 165, 63, 0.89);
    }
}

// Buttons and select
.peeping-select-bt{
    text-decoration:none;
    background-color: white;
    border: 3px solid #969696;
    border-radius: .75rem;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: .75rem 1.2rem;
    text-align: center;
    transition-duration: .2s;
    transition-property: background-color,border-color,color,fill,stroke;
    transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;

    &.selected{
        background-color: rgb(225, 168, 121);
    }
    &.reset{
        background-color: rgb(255, 205, 20);
    }

    &:hover {
        background-color: rgb(220, 220, 220);
    }

    &:focus {
        box-shadow: none;
        outline: 2px solid transparent;
        outline-offset: 2px;
    }
}

.select-module{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

//
.exercise-metadata{
    width: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap:0.5rem;
}