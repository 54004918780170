.button{
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 10px 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
  min-width: 7rem;
  justify-content: center;
  user-select: none;
  color: #000000;
  font-size: 1.3rem;

  &.big{
    font-size: 6rem;
  }

  &.small{
    margin: 0;
    font-size: 1rem;
  }

  &.mini{
    margin: 0;
    padding: 3px;
    font-size: 1rem;
    min-width: max-content;
  }

  &:hover {
    border: 2px solid #989898;
    background: #d3d3d3;
  }

  &.warning{
    background-color: #FFBB3D;
    border: 2px solid #77581d;
    color: black;

    &:hover{
      background-color: #dea338;
      border: 2px solid black;
      color: black;
    }
  }

  &.danger{
    background-color: rgba(230, 0, 0, 0.51);
    border: 2px solid #9f0000;
    color: white;

    &:hover{
      background-color: rgba(230, 0, 0);
      border: 2px solid black;
      color: white;
    }
  }

  &.save{
    background-color: #00aa58;
    border: 2px solid #00c266;
    color: white;
    font-weight: bold;

    &:hover{
      background-color: #00914B;
      border: 2px solid white;
      color: white;
    }
  }
}

.link {
  color: #ffffff;
  position: relative;
  text-decoration: none;
}

.link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.no-link{
  text-decoration: none;
}


// checkbox

.checkbox {
  display: flex;
  position: relative;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.checkbox input {
  display:none;
}
.checkbox__indicator {
  position: relative;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: solid 1px #6b6b6b;
}
.checkbox:hover input ~ .checkbox__indicator,
.checkbox input:focus ~ .checkbox__indicator {
  background: #ccc;
}
.checkbox input:checked ~ .checkbox__indicator {
  background: #76f331;
}
.checkbox:hover input:not([disabled]):checked ~ .checkbox__indicator,
.checkbox input:checked:focus ~ .checkbox__indicator {
  background: #76f331;
}

.checkbox__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .checkbox__indicator:after {
  display: block;
}
.checkbox .checkbox__indicator:after {
  box-sizing: content-box;
  left: 6px;
  top: 3px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkbox--checkbox input:disabled ~ .checkbox__indicator:after {
  border-color: #7b7b7b;
}