@import "../../../scss-utils/colors";

.exercise-slide {
  background-color: $color-bg--2;
  display: flex;
  border-bottom: solid 5px black;

  @media screen and (max-width: 420px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

.exercise-slide-render {
  //padding-top: 1rem;
  flex-grow: 1;
  width: 40vw;
  //margin-bottom: 1rem;

  @media screen and (max-width: 420px) {
    width: 100%;
  }
}