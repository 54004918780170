.main-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #a47b7a;
  position: absolute;
  top: 0;
  width: 100vw;
  padding: 0.5rem;
  height: 3rem;
  box-shadow: 0 2px 5px rgba(0,0,0,.1);
  z-index: 3;

  @media screen and (max-width: 420px) {
    .button {
      font-size: 0.7rem;
    }
  }

}