.export-main{
  display: flex;
  height:97%;
  margin-top: 3%;
  justify-content: center;
  .export-exercise{
    display: flex;
    //align-items: center;
    padding: 1rem;
    gap:1rem;
    flex-direction: column;
    flex:2;
    border-right: solid 1px black;
  }
}
