.sq-input {
  display: none;
}

.sq-name {
  user-select: none;
  cursor: pointer;
  margin-bottom: 0.5rem;
  display: block;
}

.number-input{
  margin-top: 0.3rem;
  padding: 0.7rem;
}


// Better

.wrapper-buttons {
  display: flex;
  background: #fff;
  //height: auto;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 20px 15px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
  gap: 0.5rem;
  flex-wrap:wrap;

  &.center{
    justify-content: center;
  }
}

.wrapper-buttons .option {
  background: #fff;
  height: 3rem;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
  min-width: 7rem;
  justify-content: center;

  .dot {
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;

    margin-right: 0.3rem;

    &::before{
      position: absolute;
      content: "";
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      background: #0069d9;
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.5);
      transition: all 0.3s ease;
    }
  }

  span {
    user-select: none;
    color: #3a3a3a;
  }

  &.checked {
    border-color: #0069d9;
    background: #0069d9;

    .dot {
      background: #fff;

      &::before {
        opacity: 1;
        transform: scale(1);
      }
    }

    & > span {
      color: #fff;
    }
  }
}