.reset-data{
    position: absolute;
    right: 3rem;
    top:1rem;
}

.reset-data button{

    background: palevioletred;
    cursor:pointer;
}