
.question{
    display: flex;
    gap: 1rem;
    font-size: 1.5em;
    border-bottom: solid 1px grey;

    margin-bottom: 0.3rem;
}

.question input{
    width: 1.5rem;
    height: 1.5rem;
}
.question-type-header{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.submit-button{
    display: flex;
    justify-content: center;
    align-items: center;
}
.submit-button button{
    font-size: 4rem;
    margin: 2rem;
    cursor:pointer;
    padding: 0.5rem;
}