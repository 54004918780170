@import "../../../scss-utils/colors";
.coding-page{
  height: 100vh;
  background-color: $color-bg--2;
  display: flex;
  flex-direction: column;
}

.coding-form{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper-next-bt{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 5rem;
}

.coding-next-bt{
  font-size: 5rem;
  padding: 10px;
  margin: 2rem;
}

.coding-main{
  //padding: 10px;

  font-size: 1.3rem;
  margin-top: 3rem;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: 420px) {
    margin-top: 6rem;
  }
}